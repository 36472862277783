:root {
    --text-primary: #b6b6b6;
    --text-secondary: #DFDFDF;
    --bg-primary: #161624;
    --bg-secondary: #141418;
}

.navbar-v3 {
    width: 100%;
    height: 40px;
    position: fixed;
    background-color: #0b0b1c;
    padding: 0px 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    font-family: 'Roboto', sans-serif;
    z-index: 999;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

}

.dashboard-icon:hover {
    cursor: pointer;
}

.add-element-wrapper {
    cursor: pointer;
    display: flex;
    align-items: inherit;
    flex-direction: row;
    color: var(--text-secondary);
    font-weight: 600;
    border: 1px solid #FFFCFC5C;
    font-size: 14px;
    align-items: center;
    justify-content: space-around;
    padding: 3px 3px;
    border-radius: 2px;
    width: 43px;
    background: linear-gradient(0deg, rgba(102, 103, 103, 0.1) 0%, rgba(46, 45, 44, 0.12) 100%);
}

.add-element-wrapper:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.027) 0%, rgba(22, 22, 22, 0.54) 100%);
}

.navbar-right-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    right: 1.5rem;
    gap: 0.5rem;
}

.navbar-right-wrapper-1 {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

}

.navbar-right-wrapper-2 {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.4rem;
}

.add-label {
    color: var(--text-secondary);
}

.ecp-icon-wrapper {
    padding: 3px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 3px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.tick-animation-container {
    max-width: 48px;
}




/* .ecp-icon-wrapper:hover {
    cursor: pointer;
    background-color: #cccccc21;

} */

.dashboard-icon {
    display: none;
}

.ecp-icon-mobile {
    display: none;
}

@media (max-width: 600px) {
    /* .ecp-icon-mobile {
        display: block;
        width: 30px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    } */

    .dashboard-icon-select {
        display: none;
    }

    .dashboardInput-wrapper {
        padding-left: 1.1rem;
    }

    .add-element-wrapper,
    .zoom-controls,
    .separator,
    .simulator-bar-wrapper,
    .deploy-button-dashboard.dashboard {
        display: none !important;
    }

    .nav-item-wrapper{
        padding: 0rem;
    }
     .simulator-bar-button {
        display: none;
    }

    .navbar-right-wrapper {
        right: 3rem !important;
    }
}

.floating-button {
    display: none;
    position: fixed !important;
    bottom: 40px !important;
    right: 40px !important;
    z-index: 1001 !important;
    background-color: #1a1b35 !important;
    border: 1px solid rgb(43, 46, 87) !important;
}

.configuration-navbar {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 1.5rem;
    align-items: center;
    gap: 5px;
}

@media ((max-height: 500px) and (max-width: 600px)) or (max-width: 600px) {
    .floating-button {
        display: inline;
    }


    .dashboard-icon-select {
        display: none;
    }

    .dashboardInput-wrapper {
        padding-left: 1.1rem;
    }

    .add-element-wrapper,
    .separator {
        display: none;
    }

    .deploy-button-dashboard.dashboard {
        display: none !important;
    }

    .simulator-bar-button {
        display: none;
    }

    .navbar-right-wrapper {
        right: 3rem !important;
    }
}

@media (max-width: 1100px) {
    .ecp-icon-wrapper2 {
        width: 100%;
        display: flex;
        align-items: start;
        border-bottom: 1px solid #2d2d38;
    }

    .nav-link {
        width: 100%;

    }

    .nav-link.active {
        background-color: #2d2d389f;
        width: 100%;
        color: white !important;

    }

    .nav-link svg,
    .nav-link img {
        /* A single min-height or width that never changes. */
        height: 1.3rem;
        width: auto;
        color: grey;
        /* or a fixed width, if you prefer */
    }

    .nav-item {
        width: 100%;
    }

    .nav-item::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #2d2d38;
        width: 0px;
        background-color: transparent;
    }

    .navbar-right-wrapper {
        right: 2rem;
        gap: 1rem;
    }

    .ecp-icon-wrapper {
        display: none;
    }

    .dashboard-icon,
    .close-icon {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-65%);
    }

    .sidebar-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(1px);
        background-color: rgba(20, 20, 20, 0.45);
        opacity: 0;
        visibility: hidden;
        z-index: 999;
    }

    .sidebar-overlay.visible {
        opacity: 1;
        visibility: visible;
    }

    .navbar-open {
        transform: translateX(0);
        //transition: transform 0.3s ease;
    }

    .navbar-closed {
        transform: translateX(-100%);
        //transition: transform 0.3s ease;
    }

    .navbar-open .navbar-nav .link-text {
        display: inline;
        margin-left: 0;
        margin-right: 1rem;
    }

    .nav-item:hover {
        background-color: transparent;
        cursor: pointer;
        border-radius: 0.4rem;
        text-decoration: underline;
    }

    /* Set a width for the sidebar on small screens */
    .navbar {
        width: 220px;
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    /* When .navbar-open is applied, slide it fully into view */
    .navbar.navbar-open {
        transform: translateX(0);
        overflow: hidden;
    }

    /* Keep .navbar-closed off-screen */
    .navbar.navbar-closed {
        transform: translateX(-100%);
    }

    /* Show link text only when the sidebar is open */
    .navbar-open .navbar-nav .link-text {
        /* display: inline; */
        margin-left: 0.5rem;
        /* adjust spacing to taste */
    }

    .navbar-open .navbar-nav .link-text {
        display: inline;
        margin-left: 0.5rem;
        margin-right: 1rem;
        color: grey;

    }

    .navbar-open {
        color: white;

    }

    /* Also show link text in ecp-icon-wrapper2 and logout-wrapper */
    .navbar-open .ecp-icon-wrapper2 .link-text,
    .navbar-open .logout-wrapper .link-text {
        display: inline;
    }

}

@media (max-width: 1100px) {

    /* Default: set link text and svg icons to grey */
    .navbar-nav .link-text,
    .navbar-nav .nav-link svg,
    .navbar-nav .nav-link img {
        color: grey;
    }

    /* When hovering over a nav-item, or when the nav-link is active,
       override to white */
    .nav-item:hover .link-text,
    .nav-item:hover .nav-link svg,
    .nav-item:hover .nav-link img,
    .nav-link.active .link-text,
    .nav-link.active svg,
    .nav-link.active img {
        color: white !important;
    }
}

@media (max-width: 1100px) {

    /* Existing rule for nav items inside navbar-nav */
    .navbar-open .navbar-nav .link-text {
        display: inline;
        margin-left: 0.5rem;
        margin-right: 1rem;
        color: grey;
    }

    /* Add similar styling for logout text */
    .navbar-open .logout-wrapper .link-text {
        display: inline;
        margin-left: 0.5rem;
        margin-right: 1rem;
        color: grey;
    }

    /* On hover and active, set both text and svg to white */
    .nav-item:hover .link-text,
    .navbar-open .navbar-nav .nav-link.active .link-text,
    .navbar-open .logout-wrapper .nav-link:hover .link-text,
    .navbar-open .logout-wrapper .nav-link.active .link-text {
        color: white !important;
    }

    .nav-item:hover .nav-link svg,
    .navbar-open .navbar-nav .nav-link.active svg,
    .navbar-open .logout-wrapper .nav-link:hover svg,
    .navbar-open .logout-wrapper .nav-link.active svg {
        color: white !important;
    }
}