/* ------------------ Sidebar (Common) Styles ------------------ */
.navbar {
    position: fixed;
    height: 100%;
    background-color: #0b0b1c;
    z-index: 999;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    font-family: "Inter", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.57143;
    letter-spacing: 0.01071em;
    padding: 0rem 0.2rem;
  }
  
  .navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    color: #f4f4f5;
    text-decoration: none;
    padding: 0.5rem;
    position: relative;
  }
  
  .nav-link.active {
    position: relative;
  }
  
  .nav-link.active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: linear-gradient(to bottom, #3865ab, #003b62);
    border-radius: 150px;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0;
  }
  
  .nav-item-wrapper {
    padding: 0.1rem 0;
  }
  .nav-item {
    position: relative;
  }

  .nav-item::after {
    content: attr(data-tooltip);
    position: absolute;
    /* Position the tooltip to the right of the icon; adjust as needed */
    left: 2.5rem;      /* how far right the tooltip should appear */
    top: 50%;          /* center vertically */
    transform: translateY(-50%);
    
    /* Basic styling */
    background-color: #161624;
    color: #f4f4f5;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    font-size: 1rem;
    white-space: nowrap;
    
    /* Start hidden */
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    
    /* Optional drop-shadow for effect */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  /* Show the tooltip on hover */
  .nav-item:hover::after {
    opacity: 1;
  }

  .nav-link svg,
  .nav-link img {
    min-height: 1.3rem;
  }
  
  .link-text {
    display: none;
    margin-left: 1rem;
  }
  
  .ecp-icon-wrapper2 {
    padding: 0.5rem;
    width: fit-content;
  }
  
  .ecp-icon {
    width: 1.35rem;
  }
  
  .logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
  }
  
  .logo svg {
    transform: rotate(0deg);
  }
  
  .nav-item:hover {
    background-color: #161624;
    cursor: pointer;
    border-radius: 0.4rem;
  }
  
  /* Sidebar simulator styles */
  .sidebar-simulator.navbar {
    width: 3rem;
    height: 100% !important;
    top: 0;
    background-color: #16162444;
    z-index: 9999;
    display: none;
  }
  
  /* Logout icon positioned at the bottom */
  .logout-wrapper {
    margin-top: auto;
    /* padding: 0.3rem; */
    margin-bottom: 1.5rem;
  }
  
  /* ------------------ Media Queries for min-width: 1100px ------------------ */
  @media only screen and (min-width: 1100px) {
    .navbar {
      display: flex;
      align-items: center;
      justify-content: start;
    }
    .navbar-nav {
      align-items: center;
      position: relative;
      top: 5px;
    }
  }
  